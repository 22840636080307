export default {
	methods: {
		checkValid(errorMap) {
			let isValid = true;
			for (const key in errorMap) {
				if (errorMap[key]) {
					isValid = false;
					break;
				}
			}
			return isValid;
		},
	},
};
