<template>
	<div class="row" :class="{ new_site: isNew }">
		<div class="site_name">
			<span class="ic_building" :class="iconClass"></span>
			<span class="txt">{{ site.siteName }}</span>
		</div>
		<div class="site_data">
			<div class="com_form">
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">사용자 수</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ site.usedUserCount }}명 /</span>
							<div class="com_ipt number" :class="{ error: siteQuotaError.purchaseUserCount }">
								<input
									class="ipt_normal"
									type="number"
									name="quantity"
									data-type="purchaseUserCount"
									:min="site.usedUserCount"
									:value="site.purchaseUserCount"
									@change="handleSiteChange"
									@keyup="handleSiteChange"
									@mouseup="handleSiteChange"
								/>
								<span class="txt">명</span>
							</div>
							<p class="sys_msg">{{ siteQuotaError.purchaseUserCount }}</p>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">계정 용량</span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form>
									<p>
										<input
											type="radio"
											:id="`accountQuotaUnlimited${site.uuid}`"
											:name="`accountQuota${site.uuid}`"
											:checked="isUnlimitedAccountQuota"
										/>
										<label
											:for="`accountQuotaUnlimited${site.uuid}`"
											@click="handleAccountQuotaLimitClick"
											data-is-limited="false"
											>무제한</label
										>
									</p>
									<p>
										<input
											type="radio"
											:id="`accountQuotaLimited${site.uuid}`"
											:name="`accountQuota${site.uuid}`"
											:checked="!isUnlimitedAccountQuota"
										/>
										<label
											:for="`accountQuotaLimited${site.uuid}`"
											@click="handleAccountQuotaLimitClick"
											data-is-limited="true"
											>직접 입력</label
										>
									</p>
									<span class="txt">{{ site.usedAccountQuota }}GB /</span>
									<div class="com_ipt number" :class="{ error: siteQuotaError.purchaseAccountQuota }">
										<input
											class="ipt_normal"
											type="number"
											name="quantity"
											data-type="purchaseAccountQuota"
											:min="minAccountQuota"
											:disabled="isUnlimitedAccountQuota"
											:value="purchaseAccountQuota"
											@change="handleSiteChange"
											@keyup="handleSiteChange"
											@mouseup="handleSiteChange"
										/>
										<span class="txt">GB</span>
									</div>
									<p class="sys_msg">{{ siteQuotaError.purchaseAccountQuota }}</p>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">공용 용량</span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form>
									<p>
										<input
											type="radio"
											name="companyQuota"
											:id="`companyQuotaUnlimited${site.uuid}`"
											:checked="isUnlimitedCompanyQuota"
										/>
										<label
											:for="`companyQuotaUnlimited${site.uuid}`"
											@click="handleCompanyQuotaLimitClick"
											data-is-limited="false"
											>무제한</label
										>
									</p>
									<p>
										<input
											type="radio"
											name="companyQuota"
											:id="`companyQuotaLimited${site.uuid}`"
											:checked="!isUnlimitedCompanyQuota"
										/>
										<label
											:for="`companyQuotaLimited${site.uuid}`"
											@click="handleCompanyQuotaLimitClick"
											data-is-limited="true"
											>직접 입력</label
										>
									</p>
									<span class="txt">{{ site.usedCompanyQuota }}GB /</span>
									<div class="com_ipt number" :class="{ error: siteQuotaError.purchaseCompanyQuota }">
										<input
											class="ipt_normal"
											type="number"
											name="quantity"
											data-type="purchaseCompanyQuota"
											:min="minCompanyQuota"
											:disabled="isUnlimitedCompanyQuota"
											:value="purchaseCompanyQuota"
											@change="handleSiteChange"
											@keyup="handleSiteChange"
											@mouseup="handleSiteChange"
										/>
										<span class="txt">GB</span>
									</div>
									<p class="sys_msg">{{ siteQuotaError.purchaseCompanyQuota }}</p>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!isNew && isAccountOn" class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">법인카드</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ site.usedCompanyCreditCardCount }}개 /</span>
							<div class="com_ipt number" :class="{ error: siteQuotaError.purchaseCompanyCreditCardCount }">
								<input
									class="ipt_normal"
									type="number"
									name="quantity"
									data-type="purchaseCompanyCreditCardCount"
									:min="site.usedCompanyCreditCardCount"
									:value="purchaseCompanyCreditCardCount"
									@change="handleSiteChange"
									@keyup="handleSiteChange"
									@mouseup="handleSiteChange"
								/>
								<span class="txt">개</span>
							</div>
							<p class="sys_msg">{{ siteQuotaError.purchaseCompanyCreditCardCount }}</p>
						</div>
					</div>
				</div>
				<div v-if="!isNew && isAccountOn" class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">개인카드 사용자</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ site.usedPersonalCardCount }}개 /</span>
							<div class="com_ipt number" :class="{ error: siteQuotaError.purchasePersonalCardCount }">
								<input
									class="ipt_normal"
									type="number"
									name="quantity"
									data-type="purchasePersonalCardCount"
									:min="site.usedPersonalCardCount"
									:value="purchasePersonalCardCount"
									@change="handleSiteChange"
									@keyup="handleSiteChange"
									@mouseup="handleSiteChange"
								/>
								<span class="txt">개</span>
							</div>
							<p class="sys_msg">{{ siteQuotaError.purchasePersonalCardCount }}</p>
						</div>
					</div>
				</div>
				<div v-if="!isNew && isAccountOn" class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">화상대화</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt number" :class="{ error: siteQuotaError.purchaseMeetingRoomCount }">
								<input
									class="ipt_normal"
									type="number"
									name="quantity"
									data-type="purchaseMeetingRoomCount"
									:min="0"
									:value="purchaseMeetingRoomCount"
									@change="handleSiteChange"
									@keyup="handleSiteChange"
									@mouseup="handleSiteChange"
								/>
								<span class="txt">개</span>
							</div>
							<p class="sys_msg">{{ siteQuotaError.purchaseMeetingRoomCount }}</p>
						</div>
					</div>
				</div>
				<div v-if="!isNew && isAccountOn" class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">세금계산서</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ site.usedTaxBillCount }}개 /</span>
							<div class="com_ipt number" :class="{ error: siteQuotaError.purchaseTaxBillCount }">
								<input
									class="ipt_normal"
									type="number"
									name="quantity"
									data-type="purchaseTaxBillCount"
									:min="site.usedTaxBillCount"
									:value="purchaseTaxBillCount"
									@change="handleSiteChange"
									@keyup="handleSiteChange"
									@mouseup="handleSiteChange"
								/>
								<span class="txt">개</span>
							</div>
							<p class="sys_msg">{{ siteQuotaError.purchaseTaxBillCount }}</p>
						</div>
					</div>
				</div>
				<div v-if="!isNew && isAccountOn" class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">은행계좌 즉시조회</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ site.usedBankAccountCount }}개 /</span>
							<div class="com_ipt number" :class="{ error: siteQuotaError.purchaseBankAccountCount }">
								<input
									class="ipt_normal"
									type="number"
									name="quantity"
									data-type="purchaseBankAccountCount"
									:min="0"
									:value="purchaseBankAccountCount"
									@change="handleSiteChange"
									@keyup="handleSiteChange"
									@mouseup="handleSiteChange"
								/>
								<span class="txt">개</span>
							</div>
							<p class="sys_msg">{{ siteQuotaError.purchaseBankAccountCount }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import baseSite from '@/components/baseSite';
import baseSiteStatus from '@/components/baseSiteStatus';
import _ from 'lodash';

const ERROR = {
	purchaseUserCount: '',
	purchaseAccountQuota: '',
	purchaseCompanyQuota: '',
	purchaseCompanyCreditCardCount: '',
	purchasePersonalCardCount: '',
	purchaseMeetingRoomCount: '',
	purchaseTaxBillCount: '',
	purchaseBankAccountCount: '',
};

export default {
	mixins: [baseSite, baseSiteStatus],
	props: ['index', 'site', 'siteStatus', 'isNew'],
	data() {
		return {
			siteQuotaError: _.cloneDeep(ERROR),
			purchaseUserCount: this.site.purchaseUserCount,
			purchaseAccountQuota: this.site.purchaseAccountQuota,
			purchaseCompanyQuota: this.site.purchaseCompanyQuota,
			purchaseCompanyCreditCardCount: this.site.purchaseCompanyCreditCardCount,
			purchasePersonalCardCount: this.site.purchasePersonalCardCount,
			purchaseMeetingRoomCount: this.site.purchaseMeetingRoomCount,
			purchaseTaxBillCount: this.site.purchaseTaxBillCount,
			purchaseBankAccountCount: this.site.purchaseBankAccountCount,
			isUnlimitedAccountQuota: this.site.purchaseAccountQuota === 0,
			isUnlimitedCompanyQuota: this.site.purchaseCompanyQuota === 0,
		};
	},
	computed: {
		minAccountQuota() {
			return this.site.usedAccountQuota || 2;
		},
		minCompanyQuota() {
			return this.site.usedCompanyQuota || 1;
		},
		iconClass() {
			let indexStr = this.index + 1;
			if (indexStr === 0) {
				indexStr = '_new';
			} else {
				indexStr = `${this.indexStr}_s`;
			}
			return `ic_bd${indexStr}`;
		},
	},
	methods: {
		setUnlimitedAccountQuota(isUnlimited) {
			if (this.isUnlimitedAccountQuota === isUnlimited) return;
			this.isUnlimitedAccountQuota = isUnlimited;
			let purchaseAccountQuota;
			if (isUnlimited) {
				purchaseAccountQuota = 0;
			} else {
				purchaseAccountQuota = Math.max(2, this.site.usedAccountQuota, this.site.purchaseAccountQuota);
			}
			this.purchaseAccountQuota = purchaseAccountQuota;
			this.site.purchaseAccountQuota = this.purchaseAccountQuota;
			this.$emit('changeSite', {
				site: this.site,
			});
			this.validateSite(this.site);
		},
		handleAccountQuotaLimitClick(e) {
			const isUnlimited = e.currentTarget.getAttribute('data-is-limited') === 'false';
			this.setUnlimitedAccountQuota(isUnlimited);
		},
		setUnlimitedCompanyQuota(isUnlimited) {
			if (this.isUnlimitedCompanyQuota === isUnlimited) return;
			this.isUnlimitedCompanyQuota = isUnlimited;
			let purchaseCompanyQuota;
			if (isUnlimited) {
				purchaseCompanyQuota = 0;
			} else {
				purchaseCompanyQuota = Math.max(1, this.site.usedCompanyQuota, this.site.purchaseCompanyQuota);
			}
			this.purchaseCompanyQuota = purchaseCompanyQuota;
			this.site.purchaseCompanyQuota = this.purchaseCompanyQuota;
			this.$emit('changeSite', {
				site: this.site,
			});
			this.validateSite(this.site);
		},
		handleCompanyQuotaLimitClick(e) {
			const isUnlimited = e.currentTarget.getAttribute('data-is-limited') === 'false';
			this.setUnlimitedCompanyQuota(isUnlimited);
		},
		handleSiteChange(e) {
			const dataType = e.currentTarget.getAttribute('data-type');
			const value = parseInt(e.currentTarget.value, 10);
			if (value === 0) {
				if (dataType === 'purchaseAccountQuota') {
					return this.setUnlimitedAccountQuota(true);
				}
				if (dataType === 'purchaseCompanyQuota') {
					return this.setUnlimitedCompanyQuota(true);
				}
			}
			this[dataType] = value;
			this.site[dataType] = this[dataType] || 0;
			this.$emit('changeSite', {
				site: this.site,
			});
			this.validateSite(this.site);
		},
	},
};
</script>
