<template>
	<div>
		<div v-if="isSystemViewable" class="container wrap_horizon_graph">
			<h2>사이트 정보</h2>
			<div class="layout_col2">
				<div class="wrap_col">
					<div class="graph_tit">
						<span class="tit">총 사용자 수</span>
						<span class="data"
							><strong>{{ siteStatus.usedUserCount }}명</strong>{{ siteStatus.purchaseUserCount }}명</span
						>
					</div>
					<div class="graph_horizon">
						<div class="using" :title="`${Math.round(usedUserRate)}%`" :style="`width: ${Math.round(usedUserRate)}%`">
							{{ Math.round(usedUserRate) }}%
						</div>
						<div
							class="notuse"
							:title="`${Math.round(remainUserRate)}%`"
							:style="`width: ${Math.round(remainUserRate)}%`"
						>
							{{ Math.round(remainUserRate) }}%
						</div>
					</div>
				</div>
				<div class="wrap_col">
					<div class="graph_tit">
						<span class="tit">총 용량</span>
						<span class="data"
							><strong>{{ siteStatus.usedQuota }} GB</strong>{{ siteStatus.purchaseQuota }} GB</span
						>
					</div>
					<div class="graph_horizon">
						<div class="using" :title="`${Math.round(usedQuotaRate)}%`" :style="`width: ${Math.round(usedQuotaRate)}%`">
							{{ Math.round(usedQuotaRate) }}%
						</div>
						<div
							class="notuse"
							:title="`${Math.round(remainVolumeRate)}%`"
							:style="`width: ${Math.round(remainVolumeRate)}%`"
						>
							{{ Math.round(remainVolumeRate) }}%
						</div>
					</div>
				</div>
			</div>
			<div class="additional_services">
				<div class="graph_tit">
					<span class="tit">부가서비스</span>
				</div>
				<ul class="service_list">
					<li>
						<span class="tit">법인카드</span>
						<span class="data"
							>{{ siteStatus.usedCompanyCreditCardCount }}개 / {{ siteStatus.purchaseCompanyCreditCardCount }}개</span
						>
					</li>
					<li>
						<span class="tit">개인카드 사용자</span>
						<span class="data"
							>{{ siteStatus.usedPersonalCardCount }}개 / {{ siteStatus.purchasePersonalCardCount }}개</span
						>
					</li>
					<li>
						<span class="tit">화상대화</span>
						<span class="data">{{ siteStatus.purchaseMeetingRoomCount }}개</span>
					</li>
					<li>
						<span class="tit">세금계산서 발행</span>
						<span class="data">{{ siteStatus.usedTaxBillCount }}개 / {{ siteStatus.purchaseTaxBillCount }}개</span>
					</li>
					<li>
						<span class="tit">은행계좌 즉시조회</span>
						<span class="data"
							>{{ siteStatus.usedBankAccountCount }}개 / {{ siteStatus.purchaseBankAccountCount }}개</span
						>
					</li>
					<li>
						<span class="tit">전자계약</span>
						<span class="data"
							>{{ siteStatus.usedElectronicContractCount }}개 / {{ siteStatus.purchaseElectronicContractCount }}개</span
						>
					</li>
				</ul>
			</div>
		</div>
		<div class="container">
			<h2>사이트별 할당 정보</h2>
			<ul class="wrap_title_btn">
				<li v-if="isSystemEditable" class="btn">
					<button @click="handleCreateSite" class="tool"><span class="txt">사이트 추가</span></button>
				</li>
				<li v-if="isSystemEditable" class="btn">
					<button @click="handleSettingQuota" class="tool"><span class="txt">쿼터 설정</span></button>
				</li>
			</ul>
			<div class="tb_list">
				<div class="tb_tit">
					<div class="label site_name">사이트 명</div>
					<!--					<div class="label admin_name">관리자</div>-->
					<div class="label user_graph">사용자</div>
					<div class="label data_graph">용량</div>
					<div class="label more_btn"></div>
				</div>
				<ul class="wrap_content_list">
					<Site
						v-for="(site, index) in editableSites"
						:key="site.uuid"
						:index="index"
						:site="site"
						:siteStatus="siteStatus"
					></Site>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import Site from '@/components/Site.vue';
import SiteLayer from '@/components/SiteLayer';
import QuotaSettingLayer from '@/components/QuotaSettingLayer';

export default {
	components: {
		Site,
	},
	async created() {
		const result = await this.$store.dispatch('FETCH_SITE_STATUS');
		if (result.data.error) {
			this.$ms.toast.show('조회에 실패하였습니다.');
		}
	},
	computed: {
		siteStatus() {
			return this.$store.state.user.siteStatus;
		},
		remainUserCount() {
			return this.siteStatus.purchaseUserCount - this.siteStatus.usedUserCount;
		},
		usedUserRate() {
			return Math.min((this.siteStatus.usedUserCount / this.siteStatus.purchaseUserCount) * 100, 100) || 0;
		},
		remainUserRate() {
			return (this.remainUserCount / this.siteStatus.purchaseUserCount) * 100 || 0;
		},
		remainVolume() {
			return this.siteStatus.purchaseQuota - this.siteStatus.usedQuota;
		},
		usedQuotaRate() {
			return Math.min((this.siteStatus.usedQuota / this.siteStatus.purchaseQuota) * 100, 100) || 0;
		},
		remainVolumeRate() {
			return (this.remainVolume / this.siteStatus.purchaseQuota) * 100 || 0;
		},
		isSystemEditable() {
			return !this.$store.getters.isSaaS && !this.$store.getters.isSiteAdmin && !this.$store.getters.isSaaSReseller;
		},
		isSystemViewable() {
			return !(this.$store.getters.isInstall && this.$store.getters.isSiteAdmin);
		},
		isSiteAdmin() {
			return this.$store.getters.isSiteAdmin;
		},
		editableSites() {
			if (this.isSiteAdmin) {
				const { uuid } = this.$store.state.user.sso;
				return this.siteStatus.sites.filter((site) => {
					return site.uuid === uuid;
				});
			} else {
				return this.siteStatus.sites;
			}
		},
	},
	methods: {
		handleCreateSite() {
			this.$modal.show(
				SiteLayer,
				{
					$store: this.$store, // vue-js-modal 버그. store 변경 발생 이후 참조가 끊어짐. store 를 넘겨줘야 사용 가능함.
					domains: this.siteStatus.domains,
					isCreate: true,
				},
				{
					classes: 'com_layer_normal layer_add_site',
					width: '800px',
					height: 'auto',
					clickToClose: false,
				},
			);
		},
		handleSettingQuota() {
			this.$modal.show(
				QuotaSettingLayer,
				{
					$store: this.$store, // vue-js-modal 버그. store 변경 발생 이후 참조가 끊어짐. store 를 넘겨줘야 사용 가능함.
				},
				{
					classes: 'com_layer_normal layer_add_site layer_quater_set',
					width: '800px',
					height: 'auto',
					clickToClose: false,
				},
			);
		},
	},
};
</script>
