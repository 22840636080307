import _ from 'lodash';

const ERROR = {
	purchaseUserCount: '',
	purchaseAccountQuota: '',
	purchaseCompanyQuota: '',
	purchaseCompanyCreditCardCount: '',
	purchaseMeetingRoomCount: '',
};

export default {
	computed: {
		indexStr() {
			let indexStr = this.index + 1;
			if (indexStr > 20) indexStr = indexStr - 20;
			if (indexStr < 10) {
				indexStr = '0' + indexStr;
			} else {
				indexStr = '' + indexStr;
			}
			return indexStr;
		},
		isAccountInstalled() {
			return !_.isEmpty(this.account);
		},
		isAccountOn() {
			return this.account.use === true;
		},
		isAccountOff() {
			return this.account.use === false;
		},
		account() {
			return _.find(this.site.channelInfo, { code: 'ACCOUNT' }) || {};
		},
	},
	methods: {
		validateSites() {
			let isValid = true;
			this.siteStatus.sites.forEach((site) => {
				if (!this.validateSite(site)) {
					isValid = false;
					return false;
				}
			});
			return isValid;
		},
		validateSite(site) {
			this.siteQuotaError = _.cloneDeep(ERROR);

			if (site.purchaseUserCount < 1 || site.purchaseUserCount > 99999) {
				this.siteQuotaError.purchaseUserCount = '임직원 수는 1이상, 99999이하를 입력해야 합니다.';
			}
			if (this.remainingUserCount < 0) {
				this.siteQuotaError.purchaseUserCount = '추가 가능한 사용자 수가 없습니다.';
			}
			if (site.usedUserCount > site.purchaseUserCount) {
				this.siteQuotaError.purchaseUserCount = `사용중인 사용자 수는 ${site.usedUserCount}입니다. 사용자 수 확인 후 입력해주세요.`;
			}
			if (this.remainingQuota < 0 && site.purchaseAccountQuota !== 0) {
				this.siteQuotaError.purchaseAccountQuota = '추가 가능한 계정 용량이 없습니다.';
			}
			if (site.purchaseAccountQuota > this.siteStatus.purchaseQuota) {
				this.siteQuotaError.purchaseAccountQuota = `총 계정 용량은 ${this.siteStatus.purchaseQuota}입니다. 총 용량 확인 후 입력해주세요.`;
			}
			if (site.purchaseAccountQuota !== 0 && site.usedAccountQuota > site.purchaseAccountQuota) {
				this.siteQuotaError.purchaseAccountQuota = `사용중인 계정 용량은 ${site.usedAccountQuota}입니다. 용량 확인 후 입력해주세요.`;
			}
			if (this.remainingQuota < 0 && site.purchaseCompanyQuota !== 0) {
				this.siteQuotaError.purchaseCompanyQuota = '추가 가능한 공용 용량이 없습니다.';
			}
			if (site.purchaseCompanyQuota > this.siteStatus.purchaseQuota) {
				this.siteQuotaError.purchaseCompanyQuota = `총 할당 공용 용량은 ${this.siteStatus.purchaseQuota}입니다. 총 용량 확인 후 입력해주세요.`;
			}
			if (site.purchaseCompanyQuota !== 0 && site.usedCompanyQuota > site.purchaseCompanyQuota) {
				this.siteQuotaError.purchaseCompanyQuota = `사용중인 공용 용량은 ${site.usedCompanyQuota}입니다. 용량 확인 후 입력해주세요.`;
			}
			if (this.remainingCompanyCreditCardCount < 0) {
				this.siteQuotaError.purchaseCompanyCreditCardCount = '추가 가능한 법인카드가 없습니다.';
			}
			if (this.remainingMeetingRoomCount < 0) {
				this.siteQuotaError.purchaseMeetingRoomCount = '추가 가능한 화상대화가 없습니다.';
			}

			return this.checkValid(this.siteQuotaError);
		},
	},
};
