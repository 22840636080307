<template>
	<div>
		<div class="lay_header">
			<h1 style="user-select: auto">{{ isCreate ? '사이트 추가' : '사이트 정보' }}</h1>
			<a class="btn_close" title="닫기" @click="handleCancel"><span class="ic_set ic_close"></span></a>
		</div>
		<div class="lay_body" style="user-select: auto">
			<div class="tab_contents">
				<ul class="tab_area">
					<li :class="{ on: activatedTab === 'base' }" @click="handleTabClick" data-type="base">
						<a :class="{ tab_error: !isValidDefaultInfo }">
							기본 정보
							<span class="ic_set ic_error" v-if="!isValidDefaultInfo"></span>
						</a>
					</li>
					<li :class="{ on: activatedTab === 'quota' }" @click="handleTabClick" data-type="quota" v-if="isCreate">
						<a :class="{ tab_error: !isValidQuotaSetting || !isValidSites }">
							쿼터 설정
							<span class="ic_set ic_error" v-if="!isValidQuotaSetting || !isValidSites"></span>
						</a>
					</li>
				</ul>
				<div class="tab_body" v-if="activatedTab === 'base'">
					<div class="com_form">
						<div class="wrap_row">
							<div class="row">
								<label class="form_tit cell">
									<span class="txt">도메인명</span>
									<span class="com_help">
										<span class="ic_set ic_info">
											<span class="tool_tip">
												사용할 메일 도메인을 선택하세요.
												<br />
												도메인 추가는 시스템어드민을 이용해주시기 바랍니다.
											</span>
										</span>
									</span>
								</label>
								<div class="form_data cell">
									<div class="com_slt">
										<select class="slt_normal" v-model="site.domain" :disabled="!isCreate">
											<option v-for="(domain, index) in domains" :key="index">
												{{ domain }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div class="wrap_row">
							<div class="row">
								<label class="form_tit cell">
									<span class="txt">사이트명</span>
								</label>
								<div class="form_data cell">
									<div class="com_ipt" :class="{ error: error.defaultInfo.siteName }">
										<input
											class="ipt_normal"
											type="text"
											v-model="site.siteName"
											placeholder="다우오피스 접속 시 노출 될 사이트명(회사명)을 입력해주세요."
											maxlength="64"
										/>
									</div>
									<p class="sys_msg">{{ error.defaultInfo.siteName }}</p>
								</div>
							</div>
						</div>
						<div class="wrap_row">
							<div class="row">
								<label class="form_tit cell">
									<span class="txt">접속 URL</span>
								</label>
								<div class="form_data cell">
									<div class="com_ipt" :class="{ error: error.defaultInfo.url }">
										<input
											class="ipt_normal"
											type="text"
											v-model="site.url"
											:disabled="!isCreate"
											placeholder="다우오피스 접속을 위한 사이트(회사)의 고유 주소입니다. ex) test.mydomain.co.kr"
										/>
									</div>
									<p class="sys_msg">{{ error.defaultInfo.url }}</p>
								</div>
							</div>
						</div>
						<div class="wrap_row">
							<div class="row">
								<label class="form_tit cell">
									<span class="txt">관리자 이름</span>
									<span class="com_help">
										<span class="ic_set ic_info">
											<span class="tool_tip">
												관리자 페이지에 접근할 수 있는 멤버 이름입니다.
												<br />
												(관리자페이지에서 수정 가능합니다.)
											</span>
										</span>
									</span>
								</label>
								<div class="form_data cell">
									<div class="com_ipt" :class="{ error: error.defaultInfo.adminName }">
										<input class="ipt_normal" type="text" v-model="site.adminName" maxlength="64" />
									</div>
									<p class="sys_msg">{{ error.defaultInfo.adminName }}</p>
								</div>
							</div>
						</div>
						<div class="wrap_row">
							<div class="row">
								<label class="form_tit cell">
									<span class="txt">아이디</span>
									<span class="com_help">
										<span class="ic_set ic_info">
											<span class="tool_tip">
												관리자 페이지에 접근할 수 있는 멤버 계정입니다.
												<br />
												(관리자페이지에서 수정 가능합니다.)
											</span>
										</span>
									</span>
								</label>
								<div class="form_data cell">
									<div class="com_ipt" :class="{ error: error.defaultInfo.adminId }">
										<input class="ipt_normal" type="text" v-model="site.adminId" maxlength="64" :disabled="!isCreate" />
									</div>
									<p class="sys_msg">{{ error.defaultInfo.adminId }}</p>
								</div>
							</div>
						</div>
						<div class="wrap_row" v-if="isCreate">
							<div class="row">
								<label class="form_tit cell">
									<span class="txt">비밀번호</span>
								</label>
								<div class="form_data cell">
									<div class="com_ipt" :class="{ error: error.defaultInfo.password }">
										<input class="ipt_normal" type="password" v-model="site.password" />
									</div>
									<p class="sys_msg">{{ error.defaultInfo.password }}</p>
								</div>
							</div>
						</div>
						<div class="wrap_row" v-if="isCreate">
							<div class="row">
								<label class="form_tit cell">
									<span class="txt">비밀번호 확인</span>
								</label>
								<div class="form_data cell">
									<div class="com_ipt" :class="{ error: error.defaultInfo.passwordConfirm }">
										<input class="ipt_normal" type="password" v-model="site.passwordConfirm" />
									</div>
									<p class="sys_msg">{{ error.defaultInfo.passwordConfirm }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<QuotaSetting v-if="activatedTab === 'quota'" ref="quotaSetting" :siteStatus="siteStatus"></QuotaSetting>
			</div>
		</div>
		<!--//content end-->
		<div class="lay_bottom">
			<div class="wrap_function">
				<button class="major" @click="handleSave">저장</button>
				<button class="minor" @click="handleCancel">취소</button>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import QuotaSetting from '@/components/QuotaSetting';
import baseSiteStatus from '@/components/baseSiteStatus';
import baseSite from '@/components/baseSite';
import {
	DEFAULT_PURCHASE_ACCOUNT_QUOTA,
	DEFAULT_PURCHASE_COMPANY_QUOTA,
	DEFAULT_PURCHASE_USER_COUNT,
} from '@/constants';
import ToastComponent from '@/components/code/modal/ToastComponent';

const ERROR = {
	defaultInfo: {
		siteName: '',
		url: '',
		adminName: '',
		adminId: '',
		password: '',
		passwordConfirm: '',
	},
	quotaSetting: {
		remainingUserCount: '',
		remainingQuota: '',
		remainingCompanyCreditCardCount: '',
		remainingPersonalCardCount: '',
		remainingMeetingRoomCount: '',
		remainingTaxBillCount: '',
		remainingBankAccountCount: '',
	},
};

export default {
	components: { QuotaSetting },
	mixins: [baseSite, baseSiteStatus],
	props: ['domains', 'isCreate', 'uuid', '$store'],
	data() {
		const siteStatus = _.cloneDeep(this.$store.state.user.siteStatus);
		let site;
		if (this.isCreate) {
			site = this.getNewSite();
			siteStatus.sites.push(site);
		} else {
			site = siteStatus.sites.find((item) => item.uuid === this.uuid);
		}
		return {
			activatedTab: 'base', // admin, quota
			purchaseAccountQuota: site.purchaseAccountQuota,
			purchaseCompanyQuota: site.purchaseCompanyQuota,
			siteStatus,
			error: _.cloneDeep(ERROR),
			isValidDefaultInfo: true,
			isValidQuotaSetting: true,
			isValidSites: true,
		};
	},
	computed: {
		isUnlimitedAccountQuota() {
			return this.site.purchaseAccountQuota === 0;
		},
		isUnlimitedCompanyQuota() {
			return this.site.purchaseCompanyQuota === 0;
		},
		site() {
			const { sites } = this.siteStatus;
			return this.isCreate ? sites[this.siteStatus.sites.length - 1] : sites.find((item) => item.uuid === this.uuid);
		},
	},
	methods: {
		handleTabClick(e) {
			this.activatedTab = e.currentTarget.getAttribute('data-type');
		},
		handleAccountQuotaClick(e) {
			const isUnlimited = e.currentTarget.getAttribute('data-is-limited') === 'false';
			if (this.isUnlimitedAccountQuota === isUnlimited) return;
			this.isUnlimitedAccountQuota = isUnlimited;
			this.purchaseAccountQuota = isUnlimited ? 0 : Math.max(2, this.purchaseAccountQuota);
			this.site.purchaseAccountQuota = this.purchaseAccountQuota;
			this.handleSiteChange({ site: this.site });
		},
		handleCompanyQuotaClick(e) {
			const isUnlimited = e.currentTarget.getAttribute('data-is-limited') === 'false';
			if (this.isUnlimitedCompanyQuota === isUnlimited) return;
			this.isUnlimitedCompanyQuota = isUnlimited;
			this.purchaseCompanyQuota = isUnlimited ? 0 : Math.max(1, this.purchaseCompanyQuota);
			this.site.purchaseCompanyQuota = this.purchaseCompanyQuota;
			this.handleSiteChange({ site: this.site });
		},
		async handleSave() {
			if (!this.validate()) return;

			this.$emit('close');
			const { sso } = this.$store.state.user;
			const result = await this.$store.dispatch(this.isCreate ? 'CREATE_SITE' : 'UPDATE_SITE', {
				membershipId: sso.membershipId,
				uuid: this.site.uuid,
				site: this.site,
				siteStatus: this.siteStatus,
			});
			if (result.data.success) {
				this.$ms.toast.show(this.isCreate ? '추가되었습니다.' : '변경되었습니다.');
			} else {
				this.$ms.toast.show('실패하였습니다.');
			}
		},
		handleCancel() {
			this.$emit('close');
		},
		validate() {
			this.error = _.cloneDeep(ERROR);

			this.isValidDefaultInfo = this.validateDefaultInfo();
			this.isValidQuotaSetting = this.isCreate ? this.validateQuotaSetting() : true;
			this.isValidSites = this.isCreate ? this.validateSites() : true;

			return this.isValidDefaultInfo && this.isValidQuotaSetting && this.isValidSites;
		},
		validateDefaultInfo() {
			const { defaultInfo } = this.error;
			if (this.site.siteName.length < 2 || this.site.siteName.length > 64) {
				defaultInfo.siteName = '사이트명은 2자 이상, 64자 이하를 입력해야 합니다.';
			}
			if (this.site.url === '') {
				defaultInfo.url = '접속 URL 을 입력해주세요.';
			}
			const url = this.site.url;
			if (!/^([a-z0-9.-]+\.[a-z0-9-]+)+$/.test(url) || url.length < 1 || url.length > 64) {
				defaultInfo.url =
					'접속 URL 은 sample.도메인명.co.kr 형태로 3자 이상, 64자 이하의 영문, 숫자, -, . 만 입력해야 합니다.';
			}
			if (!this.site.adminName || this.site.adminName.length < 2 || this.site.adminName.length > 64) {
				defaultInfo.adminName = '관리자 이름은 2자 이상, 64자 이하를 입력해야 합니다.';
			}
			if (!/^([0-9A-Za-z_-]{3,64})+$/.test(this.site.adminId)) {
				defaultInfo.adminId = '관리자 ID는 3자 이상, 64자 이하의 영문, 숫자, -, _ 만 입력해야 합니다.';
			}
			if (this.isCreate && !this.site.password) {
				defaultInfo.password = '비밀번호를 입력해주세요.';
			}
			if (this.isCreate && !this.site.passwordConfirm) {
				defaultInfo.passwordConfirm = '비밀번호를 입력해주세요.';
			}
			if (this.isCreate && this.site.password !== this.site.passwordConfirm) {
				defaultInfo.passwordConfirm = '비밀번호가 다릅니다.';
			}

			return this.checkValid(defaultInfo);
		},
		getNewSite() {
			return {
				domain: this.domains[0],
				siteName: '',
				usedUserCount: DEFAULT_PURCHASE_USER_COUNT,
				purchaseUserCount: DEFAULT_PURCHASE_USER_COUNT,
				usedAccountQuota: 0,
				purchaseAccountQuota: DEFAULT_PURCHASE_ACCOUNT_QUOTA,
				usedCompanyQuota: 0,
				purchaseCompanyQuota: DEFAULT_PURCHASE_COMPANY_QUOTA,
				url: '',
				period: '',
				businessNumber: '',
				item: '',
				adminName: '',
				adminId: '',
				password: '',
				passwordConfirm: '',
				usedMeetingRoomCount: 0,
				purchaseMeetingRoomCount: 0,
				usedCompanyCreditCardCount: 0,
				purchaseCompanyCreditCardCount: 0,
				usedPersonalCardCount: 0,
				purchasePersonalCardCount: 0,
				usedTaxBillCount: 0,
				purchaseTaxBillCount: 0,
				usedBankAccountCount: 0,
				purchaseBankAccountCount: 0,
				usedQuota: 0,
				purchaseQuota: 0,
			};
		},
	},
};
</script>
