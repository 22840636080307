<template>
	<div>
		<div class="lay_header">
			<h1 style="user-select: auto">쿼터 설정</h1>
			<a class="btn_close" title="닫기" @click="handleCancel"><span class="ic_set ic_close"></span></a>
		</div>
		<div class="lay_body" style="user-select: auto">
			<QuotaSetting ref="quotaSetting" :siteStatus="siteStatus"></QuotaSetting>
		</div>
		<div class="lay_bottom">
			<div class="wrap_function">
				<button class="major" @click="handleSave">저장</button>
				<button class="minor" @click="handleCancel">취소</button>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import QuotaSetting from '@/components/QuotaSetting';
import baseSiteStatus from '@/components/baseSiteStatus';
import baseSite from '@/components/baseSite';

const ERROR = {
	quotaSetting: {
		remainingUserCount: '',
		remainingQuota: '',
		remainingCompanyCreditCardCount: '',
		remainingMeetingRoomCount: '',
	},
};

export default {
	name: 'QuotaSettingLayer',
	components: { QuotaSetting },
	mixins: [baseSiteStatus, baseSite],
	props: ['$store'],
	data() {
		return {
			siteStatus: _.cloneDeep(this.$store.state.user.siteStatus),
			error: _.cloneDeep(ERROR),
		};
	},
	methods: {
		async handleSave() {
			this.error = _.cloneDeep(ERROR);
			if (!this.validateQuotaSetting() || !this.validateSites()) return;

			const { sso } = this.$store.state.user;
			const result = await this.$store.dispatch('SET_SITE_STATUS', {
				membershipId: sso.membershipId,
				siteStatus: this.siteStatus,
			});
			if (result.data.success) {
				this.$ms.toast.show('변경되었습니다.');
			} else {
				this.$ms.toast.show('실패하였습니다.');
			}
			this.$emit('close');
		},
		handleCancel() {
			this.$emit('close');
		},
	},
};
</script>
