<template>
	<li class="content_list">
		<div class="row">
			<div class="td-cell site_name">
				<span class="ic_building" :class="iconClass"></span>
				<div class="wrap_site">
					<span class="name">{{ site.siteName }}</span>
					<span class="btn_wrap" v-on:click="toggleOpened">
						<span class="ic_set" :class="`ic_${isOpenedMoreInfo ? 'close' : 'open'}_con`"></span>
					</span>
					<a :href="`//${site.url}`" class="site_url">{{ site.url }}</a>
				</div>
			</div>
			<div class="td-cell user_graph">
				<div
					class="wrap_graph_horizon_s"
					:class="{ state_caution: isUsedUserWarning }"
					:title="`${Math.round(usedUserRate)}%`"
				>
					<div class="graph_horizon_s">
						<div class="using" :style="`width: ${usedUserRate}%`"></div>
					</div>
					<div class="data_value">
						<span class="using">{{ site.usedUserCount }}명</span>
						<span class="notuse">{{ site.purchaseUserCount }}명</span>
					</div>
				</div>
			</div>
			<div class="td-cell data_graph">
				<div class="wrap_graph_horizon_s tit_graph" :class="{ state_caution: isUsedAccountQuotaWarning }">
					<span class="txt">계정</span>
					<div class="graph_horizon_s" v-if="site.purchaseAccountQuota" :title="`${Math.round(usedAccountQuotaRate)}%`">
						<div class="using" :style="`width: ${usedAccountQuotaRate}%`"></div>
					</div>
					<div class="data_value">
						<span class="using">{{ site.usedAccountQuota }} GB</span>
						<span class="notuse">{{ site.purchaseAccountQuota ? site.purchaseAccountQuota + ' GB' : '무제한' }}</span>
					</div>
				</div>
				<div class="wrap_graph_horizon_s tit_graph" :class="{ state_caution: isUsedCompanyQuotaWarning }">
					<span class="txt">공용</span>
					<div class="graph_horizon_s" v-if="site.purchaseCompanyQuota" :title="`${Math.round(usedCompanyQuotaRate)}%`">
						<div class="using" :style="`width: ${usedCompanyQuotaRate}%`"></div>
					</div>
					<div class="data_value">
						<span class="using">{{ site.usedCompanyQuota }} GB</span>
						<span class="notuse">{{ site.purchaseCompanyQuota ? site.purchaseCompanyQuota + ' GB' : '무제한' }}</span>
					</div>
				</div>
			</div>
			<div class="td-cell more_btn">
				<div class="wrap_option_layer">
					<span class="btn_wrap" @click.stop="handleBackdropButtonClick">
						<span class="ic_set ic_more"></span>
					</span>
					<div class="array_option" ref="backdropLayer" @click.stop="handleLayerClick" v-if="isOpenedLayer">
						<ul class="array_type">
							<li v-if="!isAccountInstalled" @click="handleAddAccounting"><span>경리회계 추가</span></li>
							<li v-if="isAccountOff" @click="handleOnAccounting"><span>경리회계 ON</span></li>
							<li v-if="isAccountOn" @click="handleOffAccounting"><span>경리회계 OFF</span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<transition name="slide">
			<div class="row_service" v-if="isOpenedMoreInfo">
				<div class="service_info">
					<ul class="service_list" v-if="isAccountOn">
						<li>
							<p class="tit">
								법인카드 ({{ site.purchaseCompanyCreditCardCount }}개 /
								{{ siteStatus.purchaseCompanyCreditCardCount }}개)
							</p>
						</li>
						<li>
							<p class="tit">
								개인카드 사용자 ({{ site.purchasePersonalCardCount }}개 / {{ siteStatus.purchasePersonalCardCount }}개)
							</p>
						</li>
						<li>
							<p class="tit">
								화상대화 ({{ site.purchaseMeetingRoomCount }}개 / {{ siteStatus.purchaseMeetingRoomCount }}개)
							</p>
						</li>
						<li>
							<p class="tit">
								세금계산서 발행 ({{ site.purchaseTaxBillCount }}개 / {{ siteStatus.purchaseTaxBillCount }}개)
							</p>
						</li>
						<li>
							<p class="tit">
								은행계좌 즉시조회 ({{ site.purchaseBankAccountCount }}개 / {{ siteStatus.purchaseBankAccountCount }}개)
							</p>
						</li>
					</ul>
					<ul class="info_list">
						<li>
							<p class="tit">서비스 이용기간</p>
							<p class="con">{{ _displayPeriod(site.period) }}</p>
						</li>
						<li>
							<p class="tit">사업자등록번호</p>
							<p class="con">{{ site.businessNumber }}</p>
						</li>
						<li>
							<p class="tit">도메인</p>
							<p class="con">{{ site.domain }}</p>
						</li>
						<li v-if="isAccountOn">
							<p class="tit">경리회계 관리자 정보</p>
							<div class="form_data cell" style="padding-top: 5px">
								<div class="com_ipt" style="background: white">
									<input class="ipt_normal" type="text" v-model="site.accountingAdminName" maxlength="64" />
									<button class="label_state using" @click="changeAddonAdmin">변경</button>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</transition>
	</li>
</template>

<script>
import _ from 'lodash';

import backdrop from '@/components/common/backdrop';
import baseSite from '@/components/baseSite';
import SiteLayer from '@/components/SiteLayer';

const WARNING_RATE = 90;

export default {
	mixins: [backdrop, baseSite],
	components: {},
	props: ['index', 'site', 'siteStatus'],
	data() {
		return {
			isOpenedMoreInfo: false,
		};
	},
	computed: {
		remainUserCount() {
			return this.site.purchaseUserCount - this.site.usedUserCount;
		},
		usedUserRate() {
			return Math.min((this.site.usedUserCount / this.site.purchaseUserCount) * 100, 100);
		},
		usedAccountQuotaRate() {
			if (this.site.purchaseAccountQuota === 0) return 0;
			return Math.min((this.site.usedAccountQuota / this.site.purchaseAccountQuota) * 100, 100);
		},
		usedCompanyQuotaRate() {
			if (this.site.purchaseCompanyQuota === 0) return 0;
			return Math.min((this.site.usedCompanyQuota / this.site.purchaseCompanyQuota) * 100, 100);
		},
		isUsedUserWarning() {
			return WARNING_RATE < this.usedUserRate;
		},
		isUsedAccountQuotaWarning() {
			return WARNING_RATE < this.usedAccountQuotaRate;
		},
		isUsedCompanyQuotaWarning() {
			return WARNING_RATE < this.usedCompanyQuotaRate;
		},
		iconClass() {
			return `ic_bd${this.indexStr}`;
		},
		hasAccountPaidProduct() {
			const { site } = this;
			const paidCount =
				site.purchaseMeetingRoomCount +
				site.purchaseTaxBillCount +
				site.purchaseCompanyCreditCardCount +
				site.purchasePersonalCardCount +
				site.purchaseBankAccountCount;
			return paidCount > 0;
		},
	},
	methods: {
		toggleOpened() {
			this.isOpenedMoreInfo = !this.isOpenedMoreInfo;
		},
		handleLayerClick() {
			this.isOpenedLayer = false;
		},
		handleEditSite() {
			this.$modal.show(
				SiteLayer,
				{
					$store: this.$store, // vue-js-modal 버그. store 변경 발생 이후 참조가 끊어짐. store 를 넘겨줘야 사용 가능함.
					domains: this.siteStatus.domains,
					uuid: this.site.uuid,
					isCreate: false,
				},
				{
					classes: 'com_layer_normal layer_add_site',
					width: '800px',
					height: 'auto',
					clickToClose: false,
				},
			);
		},
		handleDeleteSite() {
			const { sso } = this.$store.state.user;
			this.$ms.dialog.confirm(
				'사이트 삭제',
				this.isAccountInstalled
					? `<p class="txt">경리회계 사용시 사이트 삭제가 불가합니다.<br>대표전화 1577-3019로 문의주시기 바랍니다.</p>`
					: `<p class="txt">사이트를 삭제하면, 계정정보 및 사이트에 관련된 데이터가 모두 삭제되어 복구가 불가합니다.</p>
           <p class="txt_caution">삭제 하시겠습니까?</p>
           <div class="wrap_btn_box">
             <a href="https://care.daouoffice.co.kr/hc/ko/articles/900002278843-%EB%8D%B0%EC%9D%B4%ED%84%B0-%EB%B0%B1%EC%97%85-%EA%B8%B0%EB%8A%A5-%EC%82%AC%EC%9A%A9-%EA%B0%80%EC%9D%B4%EB%93%9C" target="_blank">
               <div class="btn_box">
                 <span class="txt">삭제 전 <strong>데이터 백업</strong> 받기 안내</span>
               </div>
             </a>
           </div>`,
				async () => {
					if (this.isAccountInstalled) {
						return;
					}
					const result = await this.$store.dispatch('DELETE_SITE', {
						uuid: this.site.uuid,
						membershipId: sso.membershipId,
					});
					if (result.data.success) {
						return this.$ms.toast.show('삭제되었습니다.');
					} else {
						return this.$ms.toast.show('실패하였습니다.');
					}
				},
				() => {},
			);
		},
		handleAddAccounting() {
			this.$ms.dialog.confirm(
				'경리회계 추가',
				`<p class="txt">경리회계 개설을 위한 신청서를 작성해주시면 저희가 경리회계를 개설해드립니다!</p>
        <div class="wrap_btn_box">
            <a href="https://daouoffice.co.kr/account_consult.jsp" target="_blank">
                <div class="btn_box">
                    <span class="txt" target="_blank"><strong>경리회계 신청서 작성하기</strong></span>
                </div>
            </a>
        </div>`,
				() => {},
				() => {},
			);
		},
		handleOnAccounting() {
			this.$ms.dialog.confirm(
				'경리회계 ON',
				`<p class="txt_caution">메뉴를 ON 하시겠습니까?</p>
         <p class="txt">모든 임직원의 웹, 모바일의 채널 메뉴에 경리회계(경영지원)이 노출됩니다.<br>
         경리회계 ON에 따라 인사정보는 경영지원을 통해서만 이용 가능합니다.</p>`,
				async () => {
					const result = await this.$store.dispatch('PUT_ACCOUNT_ONOFF', this._getAccountPayload(true));
					if (result.status === 200) {
						this.$ms.toast.show('경리회계 ON 되었습니다.');
					} else {
						this.$ms.toast.show('실패하였습니다.');
					}
				},
				() => {},
			);
		},
		handleOffAccounting() {
			this.$ms.dialog.confirm(
				'경리회계 OFF',
				this.hasAccountPaidProduct
					? `<p class="txt">사용 중인 유료 상품이 있습니다.<br>
            환불 요청은 1577-3019로 문의주시기 바랍니다.</p>`
					: `<p class="txt_caution">메뉴를 OFF 하시겠습니까?</p>
             <p class="txt">모든 임직원의 채널 메뉴에서 경리회계(경영지원)은 노출되지 않습니다.<br>
             경리회계 OFF에 따라 인사정보는 다우오피스를 통해서만 이용 가능합니다.</p>`,
				async () => {
					const result = await this.$store.dispatch('PUT_ACCOUNT_ONOFF', this._getAccountPayload(false));
					if (result.status === 200) {
						this.$ms.toast.show('경리회계 OFF 되었습니다.');
					} else {
						this.$ms.toast.show('실패하였습니다.');
					}
				},
				() => {},
			);
		},
		_getAccountPayload(isOn) {
			let params = {
				code: 'ACCOUNT',
				use: isOn,
			};
			return {
				id: this.$store.state.user.membership.id,
				uuid: this.site.uuid,
				params: params,
			};
		},
		_displayPeriod(period) {
			if (period && typeof period === 'string') {
				return period.replace('2099-12-31', '만료일 없음');
			}
		},
		async changeAddonAdmin() {
			this.$ms.dialog.confirm(
				'경리회계 관리자 변경',
				'경리회계 관리자를 변경하시겠습니까?',
				async () => {
					const params = {
						companyUid: this.site.uuid,
						adminId: this.site.accountingAdminName,
						adminEmail: `${this.site.accountingAdminName}@${this.site.domain}`,
					};
					const result = await this.$store.dispatch('CHANGE_ADDON_ADMIN', params);

					if (result.status === 200) {
						this.$ms.toast.show(result.message);
					} else {
						this.$ms.toast.show(result.errorDescription);
					}
				},
				() => {},
			);
		},
	},
};
</script>
